import * as consts from './consts'
import lang from '@/lang'
import { ElMessage } from 'element-plus'

var SIGN_REGEXP = /([yMdhsm])(\1*)/g
var DEFAULT_PATTERN = consts.DEFAULT_DATE_FORMAT
export const padding = (s, len) => {
  len = len - (s + '').length
  for (var i = 0; i < len; i++) {
    s = '0' + s
  }
  return s
}

export const getQueryStringByName = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  var context = ''
  if (r != null) {
    context = r[2]
  }

  reg = null
  r = null
  return context == null || context === '' || context === 'undefined' ? '' : context
}

export const formatDate = {
  format: (date, pattern) => {
    if (!date) return ''
    if (typeof date === 'string') date = new Date(date)
    pattern = pattern || DEFAULT_PATTERN
    return pattern.replace(SIGN_REGEXP, ($0) => {
      switch ($0.charAt(0)) {
        case 'y':
          return padding(date.getFullYear(), $0.length)
        case 'M':
          return padding(date.getMonth() + 1, $0.length)
        case 'd':
          return padding(date.getDate(), $0.length)
        case 'w':
          return date.getDay() + 1
        case 'h':
          return padding(date.getHours(), $0.length)
        case 'm':
          return padding(date.getMinutes(), $0.length)
        case 's':
          return padding(date.getSeconds(), $0.length)
      }
    })
  },
  parse: (dateString, pattern) => {
    var match1 = pattern.match(SIGN_REGEXP)
    var match2 = dateString.match(/(\d)+/g)
    if (match1.length === match2.length) {
      var _date = new Date(1970, 0, 1)
      for (var i = 0; i < match1.length; i++) {
        var _int = parseInt(match2[i], 10)
        var sign = match1[i]
        switch (sign.charAt(0)) {
          case 'y':
            _date.setFullYear(_int)
            break
          case 'M':
            _date.setMonth(_int - 1)
            break
          case 'd':
            _date.setDate(_int)
            break
          case 'h':
            _date.setHours(_int)
            break
          case 'm':
            _date.setMinutes(_int)
            break
          case 's':
            _date.setSeconds(_int)
            break
        }
      }
      return _date
    }
    return null
  }
}

export const formatBoolean = (val) => {
  if (val === true) {
    return 'common.yes'
  } else if (val === false) {
    return 'common.no'
  } else {
    return val
  }
}

export const formatUserStatus = (val) => {
  for (var key of Object.keys(consts.UserStatus)) {
    if (consts.UserStatus[key].value == val) return consts.UserStatus[key].label
  }
}

export const formatApplicationType = (val) => {
  if (val === 0) {
    return 'common.resource_owner_password_grant'
  } else if (val === 1) {
    return 'common.client_credentials_grant'
  } else {
    return val
  }
}

export const formatScopeType = (val) => {
  if (val === 0) {
    return 'common.identity'
  } else if (val === 1) {
    return 'common.resource'
  } else {
    return val
  }
}

export const yyyymmdd = (dateVal) => {
  var yyyy = dateVal.getFullYear()
  var mm = dateVal.getMonth() < 9 ? '0' + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1 // getMonth() is zero-based
  var dd = dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()
  return ''.concat(yyyy).concat(mm).concat(dd)
}

export const yyyymmddhhmm = (dateVal) => {
  var yyyy = dateVal.getFullYear()
  var mm = dateVal.getMonth() < 9 ? '0' + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1 // getMonth() is zero-based
  var dd = dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()
  var hh = dateVal.getHours() < 10 ? '0' + dateVal.getHours() : dateVal.getHours()
  var min = dateVal.getMinutes() < 10 ? '0' + dateVal.getMinutes() : dateVal.getMinutes()
  return ''.concat(yyyy).concat(mm).concat(dd).concat(hh).concat(min)
}

export const yyyymmddhhmmss = (dateVal) => {
  var yyyy = dateVal.getFullYear()
  var mm = dateVal.getMonth() < 9 ? '0' + (dateVal.getMonth() + 1) : dateVal.getMonth() + 1 // getMonth() is zero-based
  var dd = dateVal.getDate() < 10 ? '0' + dateVal.getDate() : dateVal.getDate()
  var hh = dateVal.getHours() < 10 ? '0' + dateVal.getHours() : dateVal.getHours()
  var min = dateVal.getMinutes() < 10 ? '0' + dateVal.getMinutes() : dateVal.getMinutes()
  var ss = dateVal.getSeconds() < 10 ? '0' + dateVal.getSeconds() : dateVal.getSeconds()
  return ''.concat(yyyy).concat(mm).concat(dd).concat(hh).concat(min).concat(ss)
}

export const showSuccess = (message) => {
  ElMessage({
    message: message,
    type: 'success'
  })
}

export const showWarning = (message) => {
  ElMessage({
    message: message,
    type: 'warning'
  })
}

export const showError = (error) => {
  ElMessage.error(lang.i18n.global.t('error.service_call_fail') + getErrorMessage(error))
}

export const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
    if (error.response.data.ExceptionMessage) {
      return error.response.data.ExceptionMessage
    } else if (error.response.data.exceptionMessage) {
      return error.response.data.exceptionMessage
    } else if (error.response.data.error) {
      return error.response.data.error.message
    } else if (error.response.data.message) {
      return error.response.data.message
    } else if (error.response.data) {
      return error.response.data
    }
  } else if (error.message) {
    return error.message
  } else {
    return error
  }
}

export const commonValidation = {
  require(msg) {
    return {
      required: true,
      message: msg,
      trigger: 'blur'
    }
  },
  integer(msg) {
    return {
      required: true,
      pattern: /^[0-9]+$/,
      message: msg
    }
  },
  float(msg) {
    return {
      required: true,
      pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
      message: msg
    }
  },
  minLength(length, msg) {
    return {
      min: length,
      message: msg
    }
  },
  maxLength(length, msg) {
    return {
      max: length,
      message: msg
    }
  },
  betweenLength(min, max, msg) {
    return {
      min: min,
      max: max,
      message: msg
    }
  }
}

export const getEnumLabel = (enumObj, val) => {
  for (const item of Object.values(enumObj)) {
    if (item.value === val) {
      return item.label
    }
  }

  return ''
}

export const toCamelCase = (str) => {
  if (!str) {
    return ''
  }

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const toSnakeCase = (str) => {
  if (!str) {
    return ''
  }
  return str.replace(/([A-Z])/g, (match, p1, offset) => {
    return offset === 0 ? p1.toLowerCase() : `_${p1.toLowerCase()}`
  })
}

export const toKebabCase = (str) => {
  if (!str) {
    return ''
  }
  return str.replace(/([A-Z])/g, (match, p1, offset) => {
    return offset === 0 ? p1.toLowerCase() : `-${p1.toLowerCase()}`
  })
}

export const toPascalCaseWithSpace = (str) => {
  if (!str) {
    return ''
  }
  return str.replace(/([A-Z])/g, (match, p1, offset) => {
    return offset === 0 ? p1.toUpperCase() : ` ${p1.toUpperCase()}`
  })
}
