import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'

let zhCn = {
  el: zhLocale.el,
  common: {
    system_name: '认证服务器',
    current_locale: '中文',
    current_user: '当前用户',
    chinese: '中文',
    english: 'English',
    search: '查询',
    cancel: '取消',
    submit: '提交',
    confirm: '确认',
    add: '新增',
    create: '创建',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    batch_delete: '批量删除',
    batch_disable: '批量禁用',
    remove: '移除',
    import: '导入',
    export: '导出',
    export_app_data: '导出的应用数据_',
    export_user_data: '导出的用户数据_',
    export_client_data: '导出的客户端数据_',
    export_api_resource_data: '导出的接口资源数据_',
    export_identity_resource_data: '导出的身份数据_',
    export_api_scope_data: '导出的接口作用域数据_',
    action: '操作',
    delete_confirm: '确认删除?',
    state_confirm: '确认改变状态?',
    batch_delete_confirm: '确认批量删除？',
    batch_disable_confirm: '确认批量禁用?',
    prompt: '提示',
    no_record: '未选择任何记录。',
    create_success: '创建成功！',
    update_success: '更新成功！',
    delete_success: '删除成功！',
    disable_success: '数据禁用成功！',
    batch_delete_success: '批量删除成功!',
    batch_disable_success: '批量禁用成功!',
    uId: 'UId',
    name: '名称',
    display_name: '显示名称',
    type: '类型',
    enabled: '是否启用',
    newUId: '是否创建新的uid',
    generateUId: '生成UId',
    description: '描述',
    create_by: '创建人',
    create_time: '创建时间',
    update_by: '修改人',
    update_time: '修改时间',
    mandatory: '必填',
    format_error: '格式错误',
    import_prompt: '将文件拖到此处，或点击上传',
    import_tip: '只能上传xlsx文件',
    import_json_tip: '只能上传json文件',
    import_success: '导入成功',
    import_failed: '导入失败',
    exist_in_upload_file: '用户名已存在于与上传的文件中',
    need_file_data: '请上传合法的文件',
    upload_success: '上传成功',
    upload_failed: '上传失败',
    app_name: '应用名称',
    apps: '应用',
    roles: '角色',
    app: '应用',
    role: '角色',
    module: '模块',
    module_name: '模块名称',
    please_select: '请选择',
    all: '全部',
    save: '保存',
    yes: '是',
    no: '否',
    form_user: '表单用户',
    ad_user: 'AD用户',
    external_user: '外部用户',
    resource_owner_password_grant: 'Resource Owner Password',
    client_credentials_grant: 'Client Credentials',
    min_length_validation: '至少 {length} 个字符。',
    max_length_validation: '最多 {length} 个字符.',
    between_length_validation: '在 {min} 和 {max} 个字符之间。',
    identity: '标识',
    resource: '资源',
    start_date: '开始时间',
    end_date: '结束时间',
    to: '至',
    last_three_month: '最近3个月',
    last_month: '上个月',
    last_week: '上周',
    last_three_week: '最近3周',
    detail: '详情',
    ok: '确认',
    system_info: '系统信息',
    os_platform: '操作系统',
    runtime_version: '运行时版本',
    memory_usage: '内存使用',
    app_version: '系统版本',
    app_build_time: '构建时间',
    app_git_commit: '提交哈希',
    uid: 'UId',
    select_date: '实则时间',
    key: '键',
    value: '值',
    day_ago: '天前',
    days_ago: '天前',
    hour_ago: '小时前',
    hours_ago: '小时前',
    minute_ago: '分钟前',
    minutes_ago: '分钟前',
    second_ago: '秒前',
    seconds_ago: '秒前',
    year_ago: '年前',
    years_ago: '年前',
    a_few_seconds_ago: '几秒钟前',
    a_few_seconds: '几秒前',
    in_a_few_seconds: '几秒内',
    a_minute_ago: '1分钟前',
    an_hour_ago: '1小时前',
    a_day_ago: '1天前',
    a_month_ago: '1个月前',
    months_ago: '个月前',
    a_year_ago: '1年前',
    not_json_slice: '不是JSON格式的数组',
    client_id: '客户端Id',
    ad_user_not_exist: 'Ad用户不存在',
    please_select_file_to_upload: '请选择上传文件。',
    reset: '重置',
    sort_index: '排序',
    input_cannot_empty: '输入框不能为空',
    common_filter: '常用过滤',
    profile: '个人信息',
    environment: '环境',
    true: '是',
    false: '否'
  },
  enum: {
    user_status: {
      active: '启用',
      disabled: '禁用',
      unconfirmed: '未确认'
    },
    email_template: {
      user_registration: '用户注册',
      reset_password: '密码重置'
    }
  },
  router: {
    home: '首页',
    administration: '后台管理',
    user_main: '用户管理',
    app_main: '应用',
    application: '应用',
    role_main: '角色',
    module_main: '模块',
    resource_main: '资源',
    operation_main: '操作',
    permission_main: '权限',
    client_main: '客户端',
    organization: '组织',
    oidc: 'OpenID Connect',
    api_resource_main: 'Api Resource',
    api_scope_main: 'Api Scope',
    identity_resource_main: 'Identity Resource',
    menu_group_main: '菜单组',
    menu_main: '菜单',
    asset_main: '资源库',
    system: '系统',
    system_config_main: '系统设置',
    system_log_main: '系统日志',
    refresh_token_main: 'Refresh Token',
    resource: '资源',
    role: '角色',
    loading: '正在加载...',
    error: '错误',
    access_denied: '禁止访问',
    not_found: '找不到相关内容。',
    privacy_policy_main: '隐私条款',
    information_collection_agreement_main: '信息收集条款',
    email_template_main: 'Email模板',
    short_message_setting_main: '短信设置',
    notification_main: '通知',
    group_main: '组'
  },
  error: {
    error: '错误',
    back_to_home: '回到首页',
    service_call_fail: '调用服务失败，详情：'
  },
  signin: {
    signin: '登录',
    please_signin: '请登录',
    user_name: '用户名',
    password: '密码',
    remember_password: '记住密码',
    user_name_missing: '请输入用户名。',
    password_missing: '请输入密码。',
    terms_and_conditions: '条款和条件',
    privacy_statement: '隐私声明',
    signin_failed: '登录失败',
    sign_in_failed_prompt: '登录失败，原因: {reason}'
  },
  home: {
    my_message: '我的消息',
    profile: '个人设置',
    settings: '设置',
    logout: '退出',
    logout_prompt: '确认退出吗？',
    logout_caption: '提示',
    clear_cache: '清理缓存',
    cache_cleared_success: '缓存清理成功',
    total_users: '用户总数',
    all_users: '所有用户',
    users: '用户',
    total_applications: '应用总数',
    applications: '应用',
    all_applications: '所有应用',
    total_clients: '客户端总数',
    clients: '客户端',
    all_clients: '所有客户端',
    top_active_user: '活跃用户排名',
    top_active_user_header: '过去一周, 一共有',
    top_active_user_content: '用户登录了',
    top_active_user_footer: '次系统.',
    monthly_login_overview: '月度登录数据趋势',
    active_application: '活跃应用',
    active_application_desc: '下面的饼图描述了活跃应用的比例',
    last_login: '最近登录',
    messages: '条记录',
    login_record: '登录记录',
    recent_logins: '最新登录记录',
    recent_login_failures: '最新登录失败记录',
    last_login_record: '最近10条登录记录',
    login_in: '登录了',
    login: '登录',
    today_logins: '今日登录次数',
    today_login_counts: '今日登录次数',
    login_counts: '登录次数',
    user_counts: '用户数量',
    recent_operations: '最新系统操作',
    type: '类型',
    category: '类别',
    source: '源',
    operation: '操作',
    operator_name: '操作人员',
    operation_object: '操作对象',
    operation_time: '操作时间',
    operation_proportion: '操作分布',
    fullscreen: '全屏',
    exit_fullscreen: '退出全屏',
    fullscreen_not_supported: '您的浏览器不支持全屏模式。',
    theme: '主题',
    theme_default: '默认',
    theme_dark: '深色'
  },
  user: {
    type: '类型',
    form_user: '表单用户',
    ad_user: 'Ad用户',
    subject: 'Subject',
    status: '状态',
    user_name: '用户名',
    password: '密码',
    enabled: '启用',
    disabled: '禁用',
    family_name: '姓',
    given_name: '名',
    email: 'Email',
    role: '角色',
    last_login_time: '最近登录时间',
    user_permission: '用户权限',
    change_password: '修改密码',
    password_confirm: '确认密码',
    password_not_match: '密码不匹配',
    password_changed: '密码更新',
    add_item: '新增',
    two_factor_enabled: '2FA启用',
    lockout_enabled: '锁定启用',
    lockout_end: '锁定结束时间',
    access_failed_count: '登录失败次数'
  },
  client: {
    client_id: 'Client Id',
    client_name: 'Client Name',
    client_type: 'Client Type',
    basic: 'Basic Information',
    general: 'General Settings',
    authentication: 'Authentication Settings',
    token: 'Token Settings',
    consent: 'Consent Settings',
    device: 'Device',
    protocol_type: 'Protocol Type',
    require_client_secret: 'Require Client Secret',
    require_pkce: 'Require Pkce',
    allow_plain_text_pkce: 'Allow Plain Text Pkce',
    allow_offline_access: 'Allow Offline Access',
    allow_access_tokens_via_browser: 'Allow Browser Access Tokens',
    description: 'Description',
    add_scope: 'Add Scope',
    client_scopes: 'Client Scopes',
    add_item: 'Add Item',
    redirect_uris: 'Redirect Uris',
    duplicated_item: 'Duplicated Item',
    client_grant_types: 'Client Grant Types',
    enable_local_login: 'Enable Local Login',
    front_channel_logout_uri: 'Front Channel Logout Uri',
    front_channel_logout_session_required: 'Front Channel Logout Session Required',
    back_channel_logout_uri: 'Back Channel Logout Uri',
    back_channel_logout_session_required: 'Back Channel Logout Session Required',
    client_post_logout_redirect_uris: 'Client Post Logout Redirect Uris',
    client_idp_restrictions: 'Client Idp Restrictions',
    user_sso_lifetime: 'User SSO Lifetime',
    identity_token_lifetime: 'Identity Token Lifetime',
    access_token_lifetime: 'Access Token Lifetime',
    access_token_type: 'Access Token Type',
    authorization_code_lifetime: 'Authorization Code Lifetime',
    absolute_refresh_token_lifetime: 'Absolute Refresh Token Lifetime',
    sliding_refresh_token_lifetime: 'Sliding Refresh Token Lifetime',
    refresh_token_usage: 'Refresh Token Usage',
    refresh_token_expiration: 'Refresh Token Expiration',
    client_cors_origins: 'Client Cors Origins',
    update_access_token_claims_on_refresh: 'Update Access Token Claims On Refresh',
    include_jwt_id: 'Include Jwt Id',
    always_send_client_claims: 'Always Send Client Claims',
    always_include_user_claims_in_id_token: 'Always Include User Claims In Id Token',
    client_claims_prefix: 'Client Claims Prefix',
    pair_wise_subject_salt: 'Pair Wise Subject Salt',
    require_consent: 'Require Consent',
    allow_remember_consent: 'Allow Remember Consent',
    client_uri: 'Client Uri',
    logo_uri: 'Logo Uri',
    user_code_type: 'User Code Type',
    device_code_lifetime: 'Device Code Lifetime',
    client_secrets: 'Client Secrets',
    secret_type: 'Secret Type',
    expiration: 'Expiration',
    creation_time: 'Creation Time',
    secret_value: 'Secret Value',
    hash_type: 'Hash Type',
    no_expiration: 'No Expiration',
    client_properties: 'Client Properties',
    duplicated_key: 'Duplicated Key',
    client_claims: 'Client Claims',
    type: 'Type',
    duplicated_secret: 'Duplicated Secret.',
    duplicated_type: 'Duplicated Type',
    option: 'Option',
    custom_option: 'Custom Option',
    client_id_exist: 'Client Id Exist',
    api_resource_name_exist: 'Api Resource Name Exist',
    api_identity_resource_name_exist: 'Api Identity Resource Name Exist',
    api_scope_name_exist: 'Api Scope Name Exist'
  },
  api_resource: {
    last_accessed_time: 'Last Access Time',
    non_editable: 'Non Editable',
    api_resource_claims: 'Api Resource Claims',
    api_resource_scopes: 'Api Resource Scopes',
    api_resource_secrets: 'Api Resource Secrets',
    api_resource_properties: 'Api Resource Properties',
    add_item: 'Add Item',
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Show In Discovery Document',
    secret_type: 'Secret Type',
    expiration: 'Expiration',
    creation_time: 'Creation Time',
    secret_value: 'Secret Value',
    hash_type: 'Hash Type',
    no_expiration: 'No Expiration',
    duplicated_key: 'Duplicated Key',
    name: 'Api Resource Name'
  },
  api_scope: {
    non_editable: 'Non Editable',
    last_accessed_time: 'Last Accessed Time',
    api_scope_claims: 'Api Scope Claims',
    api_scope_properties: 'Api Scope Properties',
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Show In Discovery',
    add_item: 'Add Item',
    name: 'Api Scope Name'
  },
  identity_resource: {
    required: 'Required',
    emphasize: 'Emphasize',
    show_in_discovery_document: 'Discovery Doc',
    non_editable: 'Non Editable',
    identity_resource_claims: 'Identity Resource Claims',
    identity_resource_properties: 'Identity Resource Properties',
    add_item: 'Add Item',
    name: 'Api Identity Resource Name'
  },
  app: {
    key: 'Key'
  },
  permission: {
    permissions_saved: '权限保存成功！',
    other_resource: '其他资源(无模块)'
  },
  menu: {
    target: 'Target',
    sort_index: '排序',
    translation_key: '翻译键',
    uri: 'Uri',
    create_root: '创建根菜单',
    create_sub: '创建子菜单',
    menu_group: '菜单组',
    move_success: '以动成功',
    component: '组件',
    icon: '图标',
    is_leaf: '是否叶子节点',
    pascal_name: 'PascalName',
    quick_fill: '快速填写'
  },
  system_config: {
    key: '键',
    value: '值',
    description: '描述'
  },
  system_log: {
    operation_time: '操作时间',
    operation_object: '操作对象',
    operation_object_id: '操作对象Id',
    operation: '操作',
    operator_ip: '操作者Ip',
    operator_name: '操作者Name',
    category: '类别',
    source: '源',
    type: '类型',
    id: 'Id',
    data: '数据',
    remark: '备注'
  },
  asset: {
    key: '键',
    value: '值',
    physical_path: '物理路径',
    physical_file_name: '物理文件名',
    file_name: '文件名',
    tag: '标签',
    asset_file: '资源文件'
  },
  refresh_token: {
    client_id: 'Client Id',
    expires_utc: 'Expires Utc',
    issued_utc: 'Issued Utc',
    name: 'Name',
    subject: 'Subject',
    token_id: 'Token Id',
    id: 'Id'
  },
  privacy_policy: {
    version: '版本',
    language_code: '语言',
    content: '内容'
  },
  information_collection_agreement: {
    version: '版本',
    language_code: '语言',
    content: '内容'
  },
  email_template: {
    type: '类型',
    language_code: '语言',
    subject: '主题',
    content: '内容',
    mail_cc: '邮件Cc',
    mail_bcc: '邮件Bcc'
  },
  message: '你好'
}

export default zhCn
